import React from "react"
import { Navigation, Footer, Seo } from "../components"

export const Layout = props => {
  return (
    <>
      <div className="font-sans mx-auto flex flex-col min-h-screen">
        <Seo />
        <Navigation />
        <div className="flex-grow">{props.children}</div>
        <Footer />
      </div>
    </>
  )
}
