import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export const Testimonial = () => {
  const data = useStaticQuery(query)
  const testimonials = data.allMdx.edges
  const settings = {
    autoplay: true,
    infinite: true,
    speed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <>
      <section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
        <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative">
            <Slider {...settings}>
              {testimonials.map(({ node: testimonial }) => (
                <blockquote
                  className="mt-8 md:flex-grow md:flex md:flex-col"
                  key={testimonial.id}
                >
                  <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-500">
                    <svg
                      className="relative transform -translate-x-3 translate-y-5 h-8 w-8 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 32 32"
                    >
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg>
                    <div className="relative">
                      <MDXRenderer>{testimonial.body}</MDXRenderer>
                    </div>
                  </div>
                  <footer className="mt-8">
                    <div className="md:flex md:items-center md:justify-center">
                      <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                        <div className="text-base leading-6 font-medium text-gray-500">
                          -{testimonial.frontmatter.name}
                        </div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </>
  )
}

export const query = graphql`
  {
    allMdx(limit: 5, sort: { order: DESC, fields: frontmatter___date }) {
      edges {
        node {
          id
          body
          frontmatter {
            name
            date
          }
        }
      }
    }
  }
`
