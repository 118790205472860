import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export const CoverImage = () => {
  const data = useStaticQuery(graphql`
    query {
      coverImage: file(relativePath: { eq: "cover_image.jpg" }) {
        publicURL
      }
    }
  `)

  return (
    <div className="hidden lg:block w-6/12 h-1/2 sm:m-8">
      <img
        className="rounded-lg shadow-lg object-cover object-center absolute inset-0 w-full h-full lg:static lg:h-auto"
        src={data.coverImage.publicURL}
        alt="Big's Axe Throwing"
        width="1310"
        height="873"
      />
    </div>
  )
}
