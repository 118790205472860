import React, { useState } from "react"

import { Link, useStaticQuery, graphql } from "gatsby"

export const Navigation = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
    }
  `)

  const [showMobileMenu, setShowMobileMenu] = useState(false)

  return (
    <>
      <div className="relative bg-white">
        <div>
          <div className="flex justify-between items-center border-b-2 border-gray-100 py-2 md:justify-start md:space-x-10">
            <div className="lg:w-0 lg:flex-1">
              <Link to="/" className="flex">
                <img
                  className=" mx-6 h-10 w-auto sm:h-20"
                  src={data.logo.publicURL}
                  alt="Workflow"
                />
              </Link>
            </div>
            <div className="mr-2 -my-2 md:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                onClick={() => setShowMobileMenu(true)}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-10 px-6">
              <Link
                to="/about"
                className="text-base leading-6 font-medium text-gray-500 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 hover:text-red-500 hover:border-b-2 hover:border-gray-600"
                activeClassName="font-bold text-red-500 border-b-2 border-gray-600"
              >
                About Us
              </Link>
              <Link
                to="/events"
                className="text-base leading-6 font-medium text-gray-500 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 hover:text-red-500 hover:border-b-2 hover:border-gray-600"
                activeClassName="font-bold text-red-500 border-b-2 border-gray-600"
              >
                Events
              </Link>
              <Link
                to="/location"
                className="text-base leading-6 font-medium text-gray-500 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 hover:text-red-500 hover:border-b-2 hover:border-gray-600"
                activeClassName="font-bold text-red-500 border-b-2 border-gray-600"
              >
                Location
              </Link>
              <Link
                to="/league"
                className="text-base leading-6 font-medium text-gray-500 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 hover:text-red-500 hover:border-b-2 hover:border-gray-600"
                activeClassName="font-bold text-red-500 border-b-2 border-gray-600"
              >
                Leagues
              </Link>
              <Link
                to="/pricing"
                className="text-base leading-6 font-medium text-gray-500 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 hover:text-red-500 hover:border-b-2 hover:border-gray-600"
                activeClassName="font-bold text-red-500 border-b-2 border-gray-600"
              >
                Prices
              </Link>
              <Link
                to="/faq"
                className="text-base leading-6 font-medium text-gray-500 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 hover:text-red-500 hover:border-b-2 hover:border-gray-600"
                activeClassName="font-bold text-red-500 border-b-2 border-gray-600"
              >
                FAQ
              </Link>
            </nav>
            <div className="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
              <span className="inline-flex rounded-md shadow-sm mr-8">
                <a href="https://fareharbor.com/embeds/book/bigsaxethrowing/?full-items=yes" className="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-600 hover:bg-red-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray-900 active:bg-red-500 transition ease-in-out duration-150">Book Now</a>
              </span>
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        {showMobileMenu ? (
          <>
            <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-20">
              <div className="rounded-lg shadow-lg">
                <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5 space-y-6">
                    <div className="flex items-center justify-between">
                      <div>
                        <Link to="/">
                          <img
                            className="h-10 w-auto"
                            src={data.logo.publicURL}
                            alt="Workflow"
                          />
                        </Link>
                      </div>
                      <div className="-mr-2">
                        <button
                          type="button"
                          className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                          onClick={() => setShowMobileMenu(false)}
                        >
                          <svg
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="py-6 px-5 space-y-6">
                      <nav className="grid row-gap-8">
                        <Link
                          to="/about"
                          className="text-base leading-6 font-medium text-gray-500 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 hover:text-red-500 hover:border-b-2 hover:border-gray-600"
                          activeClassName="font-bold text-red-500 border-b-2 border-gray-600"
                        >
                          About Us
                        </Link>
                        <Link
                          to="/events"
                          className="text-base leading-6 font-medium text-gray-500 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 hover:text-red-500 hover:border-b-2 hover:border-gray-600"
                          activeClassName="font-bold text-red-500 border-b-2 border-gray-600"
                        >
                          Events
                        </Link>
                        <Link
                          to="/location"
                          className="text-base leading-6 font-medium text-gray-500 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 hover:text-red-500 hover:border-b-2 hover:border-gray-600"
                          activeClassName="font-bold text-red-500 border-b-2 border-gray-600"
                        >
                          Location
                        </Link>
                        <Link
                          to="/league"
                          className="text-base leading-6 font-medium text-gray-500 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 hover:text-red-500 hover:border-b-2 hover:border-gray-600"
                          activeClassName="font-bold text-red-500 border-b-2 border-gray-600"
                        >
                          Leagues
                        </Link>
                        <Link
                          to="/pricing"
                          className="text-base leading-6 font-medium text-gray-500 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 hover:text-red-500 hover:border-b-2 hover:border-gray-600"
                          activeClassName="font-bold text-red-500 border-b-2 border-gray-600"
                        >
                          Prices
                        </Link>
                        <Link
                          to="/faq"
                          className="text-base leading-6 font-medium text-gray-500 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 hover:text-red-500 hover:border-b-2 hover:border-gray-600"
                          activeClassName="font-bold text-red-500 border-b-2 border-gray-600"
                        >
                          FAQ
                        </Link>
                      </nav>
                      <div className="space-y-6">
                        <span className="w-full flex rounded-md shadow-sm">
                          <a href="https://fareharbor.com/embeds/book/bigsaxethrowing/?full-items=yes" className="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-600 hover:bg-red-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray-900 active:bg-red-500 transition ease-in-out duration-150">Book Now</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  )
}
