import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

export const Seo = ({ description, keywords, title, image, url, author }) => {
  const site = "https://www.bigsaxethrowing.com"
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description
          ? description
          : data.site.siteMetadata.description
        const metaTitle = title ? title : data.site.siteMetadata.title
        const metaAuthor = author ? author : data.site.siteMetadata.author
        const metaUrl = url ? url : data.site.siteMetadata.url
        const metaImage = image
          ? `${site}${image}`
          : data.site.siteMetadata.image
        const metaKeywords = keywords
          ? keywords
          : [
              "Axe Throwing",
              "Axe Throwing GA",
              "Axe Throwing Dalton GA",
              "Axe Throwing Georgia",
              "WATL",
              "watl",
              "world axe throwing league",
              "World Axe Throwing League",
              "Big's Axe Throwing",
              "The Mill at Crown Garden",
              "The Mill Dalton GA",
              "The Mill Dalton Georgia",
              "The Mill at Crown Garden Dalton",
              "The Mill at Crown Garden Dalton Georgia",
              "Axe Leagues",
              "Axe Tournaments",
              "RightOnDalton",
            ]

        return (
          <Helmet
            title={title}
            meta={[
              {
                name: `author`,
                content: metaAuthor,
              },
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: metaImage,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
            ].concat(
              metaKeywords && metaKeywords.length > 0
                ? {
                    name: `keywords`,
                    content: metaKeywords.join(`, `),
                  }
                : []
            )}
          >
            <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
            <link
              rel="stylesheet"
              href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
              integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
              crossorigin=""
            />
            <script
              src="https://unpkg.com/leaflet@1.6.0/dist/leaflet.js"
              integrity="sha512-gZwIG9x3wUXg2hdXF6+rVkLF/0Vi9U8D2Ntg4Ga5I5BZpVkVxlJWbSQtXPSiUTtC0TjtGOmxa1AJPuV0CPthew=="
              crossorigin=""
            ></script>
            {/* <!-- FareHarbor Lightframe API - do not remove - see: https://fareharbor.com/help/website/resources/lightframe-api/ --> */}
<script src="https://fareharbor.com/embeds/api/v1/?autolightframe=yes"></script>
          </Helmet>
        )
      }}
    />
  )
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        image
        author
        url
      }
    }
  }
`
