import React from "react"
import { CoverImage } from "../components"

export const Header = () => {
  return (
    <div className="flex flex-wrap justify-center">
      <CoverImage />
    </div>
  )
}
